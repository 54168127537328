import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AboveFooter from "../components/AboveFooter"
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"

export const AboutMePageTemplate = ({ seo, hero, contact, about, fixedimagesection }) => {
  return (
    <div>
      <SEO title={seo.title} description={seo.description} />
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!hero.image.childImageSharp ? hero.image.childImageSharp.fluid.src : hero.image
            })`,
          backgroundPosition: `center center`,
          backgroundSize: 'cover',
          backgroundAttachment: `fixed`,
          height: '500px',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
            width: "90%",
          }}
        >
          <div className="has-text-centered">
            <img
              style={{ width: 170 }}
              src={!!hero.midimage.image.childImageSharp ? hero.midimage.image.childImageSharp.fluid.src : hero.midimage.image}
              alt="midimage"
            />
          </div>
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
            style={{
              color: '#53c5d1',
              lineHeight: '1',
              padding: '0.25em',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
          >
            {hero.title}
          </h1>
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
            style={{
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
          >
            {hero.secondtitle}
          </h1>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-8">
              <SubTitle title={contact.title} />
              <SubTitle title={contact.secondtitle} />
            </div>
            <div className="column is-2">
              <a href={contact.twitter}>
                <button style={{ fontSize: 20, background: "#53c5d1", width: "100%" }}>Twitter</button>
              </a>
            </div>
            <div className="column is-2">
              <a href={contact.linkedin}>
                <button style={{ fontSize: 20, background: "#53c5d1", width: "100%" }}>Linkedin</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${about.sectionbg}`, padding: "4rem 0" }}>
        <div className="container">
          <Title title={about.title} color={about.textcolor} />
          <Title title={about.secondtitle} color={about.textcolor} />
          <SubTitle title={about.subheader} color={about.textcolor} margin="1.5rem 0" />
          <Descriptions descriptions={about.descriptions} color={about.textcolor} margin="1rem 0" />
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="500px" />
    </div>
  )
}

AboutMePageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contact: PropTypes.object,
  about: PropTypes.object,
  fixedimagesection: PropTypes.object,
}

const MePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutMePageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contact={frontmatter.contact}
        about={frontmatter.about}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

MePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MePage

export const pageQuery = graphql`
  query MePageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        contact {
          title
          secondtitle
          twitter
          linkedin
        }
        about {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

